html, body {
	font-family: Arial, sans-serif;
	/* background-color: #f8f9fa; */
	background-color: #ffffff !important;

}
.instagramIcon:hover {
	background-color: #8134af !important;
}

.logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap; /* Allow divs to wrap onto the next line */
	gap: 10px;
	margin: 20px 0;
  }
  
  .logo-pair {
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .logo-pair img {
	max-width: 120px; /* Adjust this value to shrink the logos */
	margin: 0 10px; /* Add some space between logos in the same pair */
  }

.logo-container img {
  flex: 1 1 auto; /* Adjust flex-basis to control when logos wrap (e.g., 22% for two lines of two) */
  max-width: 120px; /* Ensures logos do not exceed their container's width */
  min-width: 80px; 
}
  
.logo-container img.larger-logo {
	flex: 1.5; /* Gives this logo a larger flex-grow value, making it larger proportionally */
	min-width: 75px; /* Optionally, set a larger min-width */
  }

.logo-container img.smaller-logo {
	flex: 0.1; /* Gives this logo a larger flex-grow value, making it larger proportionally */
	/* min-width: 250px; Optionally, set a larger min-width */
	max-width: 50%; /* Ensures logos do not exceed their container's width */
	max-width: 700px; /* Set a maximum size for the logos */

  }

  @media (max-width: 800px) { /* Adjust the breakpoint as needed */
	.logo-container img {
	  flex-basis: 45%; /* Each logo will take up half the container's width on smaller screens */
	}
  }
  

.centered-logo {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
  }
  
.centered-logo img {
	max-width: 250px; /* Adjust this value to control the size of the logo */
  }
  
.facebook-button-container {
	display: flex;
	justify-content: center;
  }
  
.facebook-button-container .MuiButton-root {
	width: auto; /* Adjust the width of the button */
	max-width: 300px; /* Set a maximum width for the button */
  }

h1 {
	margin-bottom: 0px; /* Reduces the bottom margin of the h1 element */
  }

.login-container {
	text-align: center;
	margin: 20px;
  }

.login-heading {
	text-align: center;
	font-size: 24px; /* For h1 */
	margin: 20px 10px;
  }
  
.login-subheading {
	text-align: left;
	font-size: 22px; /* For h3 */
	margin: 10px 10px;
  }
  
.login-text {
	text-align: left;
	font-size: 20px;
	margin: 10px 10px;
  }

.login-smalltext {
	text-align: center;
	font-size: 12px;
	margin: 0px 0px;
  }

.login-logo {
	width: 20%;
	max-width: 150px;
  }

.bold-text {
	font-weight: bold;
  }
  
.text-box {
	text-align: left;
	margin: 0 auto; /* Center the box */
	padding: 20px; /* Add some padding inside the box */
	max-width: 900px; /* Set a maximum width for the box */
  }
  


.user-profile-text {
	text-align: left;
	font-size: 14px;
	margin: 5px 0;
  }
  
.user-profile-container {
	text-align: center;
	margin: 20px;
  }

  

  